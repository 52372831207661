<template>
  <div class="banner-card">
    <div class="banner-card-container" :style="bodyStyle">
      <div class="left">
        <slot name="left">
          <div class="left-title" :style="titleStyle">{{ title }}</div>
          <div class="left-content">{{ content }} <span>{{ price }}</span> </div>
          <div class="left-btn" @click="$emit('btnClick')" v-if="showBtn">{{ btnTxt || '申请试用' }}</div>
          <div class="left-btn" @click="$emit('freeClick')" v-if="freeBtn">{{ btnTxt || '免费体验' }}</div>
          <div class="left-btn" @click="$emit('useClick')" v-if="useBtn">{{ btnTxt || '申请使用' }}</div>
        </slot>
      </div>
      <div class="right">
        <img class="right-imgBox" :style="imgStyle" :src="imgSrc">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    imgSrc: String,
    bodyStyle: {
      type: String,
      default: "",
    },
    btnTxt: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    title: String,
    content: {
      type: String,
      default: "",
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    imgStyle: {
      type: String,
      default: "",
    },
    titleStyle: {
      type: String,
      default: "",
    },
    freeBtn: {
      type: Boolean,
      default: false
    },
    useBtn: {
      type: Boolean,
      default: false
    },
    price:{
      type: String,
      default: ""
    },
  }
}
</script>
<style scoped lang="scss">
.banner-card {
  height: 400px;
  background-color: #0045B7;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &-container {
    width: 1200px;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: space-between;
    .left {
      width: 640px;
      &-title {
        font-size: 36px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 20px;
        line-height: normal;
        letter-spacing: 2px
      }
      &-content {
        color: #fff;
        text-align: justify;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.8;
        letter-spacing: 1px
      }
      &-btn {
        width: 140px;
        height: 50px;
        background-color: #fff;
        border-radius: 6px;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #0B62B5;
        cursor: pointer;
        margin-top: 45px;
      }
      &-call {
        margin-top: 18px;
        font-size: 16px;
        color: #fff;
      }
    }
    .right {
      width: 530px;
      &-imgBox {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>