<template>
    <div :style="borderStyle" class="container">
        <slot name='title'>
            <div class="title" :style="titleStyle">{{ title }}</div>
            <div class="tips">{{ tips }}</div>
        </slot>
        <div class="data-list">
            <div class="card" :style="cardStyle" v-for="(item,i) in dataList" :key="i">
                <el-image :style="imgStyle" class="imgBox" :src="item.image" fit="fill" v-if="showImg"></el-image>
                <div class="card-title" :style="cardTitleStyle" v-html="item.title"></div>
                <div class="card-content" :style="cardContentStyle" v-html="item.content"></div>
            </div>
        </div>
        <slot name="footer"></slot>
    </div>
</template>
<script>
export default {
    props: {
        //外部样式
        borderStyle: {
            type: Object,
            default() {
                return {}
            }
        },
        //是否显示图片
        showImg: {
            type: Boolean,
            default: true
        },
        //标题
        title: {
            type: String,
            default: ""
        },
        //自定义标题样式
        titleStyle: {
            type: Object,
            default() {
                return {}
            }
        },
        //标题提示
        tips: {
            type: String,
            default: ""
        },
        //数据列表
        dataList: {
            type: Array,
            default() {
                return []
            }
        },
        //盒子样式
        cardStyle: {
            type: Object,
            default() {
                return {}
            }
        },
        //盒子标题样式
        cardTitleStyle: {
            type: Object,
            default() {
                return {}
            }
        },
        //盒子内容样式
        cardContentStyle: {
            type: Object,
            default() {
                return {}
            }
        },
        //图片样式
        imgStyle: {
            type: Object,
            default() {
                return {}
            }
        }
    }
}
</script>
<style scoped lang="scss">
.container {
  position: relative;
  padding: 60px 0;

  .title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
  }

  .tips {
    text-align: center;
    font-size: 18px;
    color: #333333;
    margin-top: 16px;
  }

  .data-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    margin: auto;
    box-sizing: border-box;
    margin-top: 50px;

    .card {
      width: 220px;
      height: 270px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding-top: 30px;

      &-title {
        font-size: 20px;
        color: #333;
        font-weight: bold;
        margin-top: 20px;
      }

      &-content {
        font-size: 16px;
        margin-top: 20px;
        box-sizing: border-box;
        padding: 0 12px 7px;
        text-align: center;
        line-height: 1.8;
      }
    }
  }
}

.imgBox {
  height: 48px
}
</style>