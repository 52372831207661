<template>
<div style="min-width: 1360px;">
    <new-cloude-header></new-cloude-header>
    <new-home-header title="数智管理" :content="content" :imgSrc="`${require('@/assets/img/payImages/digIntMagager.png')}`"
                     imgStyle="height:398px" :showBtn="false"/>
    <!--数字办公平台-->
    <div class="page-content">
        <div class="number-title-box">
            <div class="card-title">数字办公平台</div>
            <div class="card-tips">{{ tips_1 }}</div>
        </div>
        <div class="platform-list">
            <div class="platform-card" v-for="(item,i) in platformList" :key="i">
                <div class="platform-card-title">{{ item.title }}</div>
                <div class="platform-card-content" v-html="item.content"></div>
            </div>
        </div>
        <div class="number-btn-box">
            <div class="btn-chakan" @click="bannerClick(2)">申请试用</div>
            <div class="btn-more" @click="outTo('/digitalOffice')">查看更多</div>
        </div>
    </div>
    <!--ERP企业资源系统-->
    <div class="page-content erp-img">
        <div class="number-title-box">
            <div class="card-title">ERP企业资源系统</div>
            <div class="card-tips">{{ tips_2 }}</div>
        </div>
        <div class="erp-container finance">
            <div class="finance-left">
                <div class="finance-cell" v-for="(item,i) in erpList" :key="i">
                    <div class="finance-cell-title">{{ item.title }}</div>
                    <div class="finance-cell-tips">{{ item.content }}</div>
                </div>
                <div class="left-btns">
                    <div class="btn-chakan" @click="bannerClick(4)">申请试用</div>
                    <div class="btn-more" @click="outTo('/ERPsystem')">查看更多</div>
                </div>
            </div>
            <div class="finance-right">
                <el-image style="width: 774px; height: 480px" :src="`${require('@/assets/img/payImages/erp.png')}`"
                          fit="fill"/>
            </div>
        </div>
    </div>

    <div class="page-content">
        <div class="number-title-box">
           <div class="card-title">MES制造执行系统</div>
            <div class="card-tips">{{ tips }}</div>
       </div>
        <div class="erp-container">
            <el-image style="width: 100%; height: 520px" :src="`${require('@/assets/img/home/top2_1.png')}`" fit="fill"/>
        </div>
    </div>

    <bannerList :title="title" :titleStyle="{fontSize:'24px',color:'#fff',fontWeight:400}"
                :borderStyle="{backgroundColor:'#182041'}" :dataList="schemeList"
                :cardStyle="{backgroundColor:'#26315D',color:'#fff',height:'340px',width:'230px'}"
                :cardTitleStyle="{color:'#fff',fontSize:'18px',textAlign:'center'}"
                :cardContentStyle="{textAlign:'left'}">
        <div class="left-btns-box" slot="footer">
            <div class="btn-chakan" @click="bannerClick(1)">申请试用</div>
            <div class="btn-more" style="color: #fff;border-color: #fff" @click="outTo('/factory')">查看更多</div>
        </div>
    </bannerList>


    <!--财务管理系统-->
    <div class="page-content">
        <div class="number-title-box">
            <div class="card-title">财务管理系统</div>
            <div class="card-tips">{{ tips_3 }}</div>
        </div>
        <div class="erp-container finance">
            <div class="finance-left">
                <div class="finance-cell" v-for="(item,i) in financeList" :key="i">
                    <div class="finance-cell-title">{{ item.title }}</div>
                    <div class="finance-cell-tips">{{ item.content }}</div>
                </div>
                <div class="left-btns">
                    <div class="btn-chakan" @click="bannerClick(3)">申请试用</div>
                    <div class="btn-more" @click="outTo('/finance')">查看更多</div>
                </div>
            </div>
            <div class="finance-right">
                <el-image style="width: 774px; height: 480px" :src="`${require('@/assets/img/payImages/newcw.png')}`"
                          fit="fill"/>
            </div>
        </div>
    </div>

    <!--CRM客户关系管理系统-->
    <div class="page-content" style="background: #F0F2FF;">
        <div class="number-title-box">
            <div class="card-title">CRM客户关系管理系统</div>
            <div class="card-tips">{{ tips_4 }}</div>
        </div>
        <div class="erp-container crmBox">
            <div class="crmBox-card" v-for="(item,i) in crmList" :key="i">
                <div class="crmBox-card-title">{{ item.title }}</div>
                <div style="display: flex;align-items: flex-end;">
                    <div class="crmBox-card-sub">{{ item.subheading }}</div>
                    <i style="font-size: 30px;font-weight: bold;" class="el-icon-top"></i>
                </div>
                <div class="crmBox-line"></div>
                <div class="crmBox-content">{{ item.content }}</div>
            </div>
        </div>
        <div class="number-btn-box" style="margin-top: 40px">
            <div class="btn-chakan" @click="bannerClick(5)">申请试用</div>
            <div class="btn-more" @click="outTo('/CRMsystem')">查看更多</div>
        </div>
    </div>

    <!--SCM供应链管理系统-->
    <div class="page-content" style="background-color: #F7F8FF;">
        <div class="number-title-box">
            <div class="card-title">SCM供应链管理系统</div>
            <div class="card-tips">{{ tips_5 }}</div>
        </div>
        <div class="erp-container scmBox">
            <div class="scmBox-card" v-for="(item,i) in SCMList" :key="i">
                <div class="scmBox-card-title">{{ item.title }}</div>
                <div class="scmBox-card-tips">{{ item.content }}</div>
            </div>
        </div>
        <div class="erp-container">
            <el-image style="width: 100%; height: 500px"
                      :src="`${require('@/assets/img/home/1678955979485_0JB91pnZRK.png')}`" fit="fill"/>
        </div>
        <div class="number-btn-box" style="margin-top: 40px">
            <div class="btn-chakan" @click="bannerClick(6)">申请试用</div>
            <div class="btn-more" @click="outTo('/SCMsystem')">查看更多</div>
        </div>
    </div>

    <!--WMS仓库管理系统-->
    <div class="page-content">
        <div class="number-title-box">
            <div class="card-title">WMS仓库管理系统</div>
            <div class="card-tips">{{ tips_6 }}</div>
        </div>
        <div class="erp-container">
            <div class="wmsTabs">
                <div @click="wmsIndex = i" :class="{isActive:wmsIndex == i}" class="wmsTabs-item" v-for="(item,i) in wmsList"
                     :key="i">{{ item.title }}
                </div>
            </div>
        </div>
        <div class="erp-container" style="text-align: center;">
            <el-image style=" height: 120px" :src="WMSImgSrc[1]" fit="contain"/>
            <el-image style="height: 300px" :src="WMSImgSrc[0]" fit="contain"/>
        </div>
    </div>


    <div class="page-content BGI2">
        <div class="card-title" style="font-size: 24px;color: #fff;text-align: center">仓储物流改善立竿见影
            助力企业持续提质降本增效
        </div>
        <div class="erp-container">
            <div class="logistics-box">
                <div class="logistics-box-cell" v-for="(item,i) in logistics" :key="i">
                    <div class="logistics-box-cell-title">
                        <div class="logistics-box-cell-title-left">{{ item.title }}</div>
                        <div class="logistics-box-cell-title-right">{{ item.num }}</div>
                    </div>
                    <div class="logistics-box-content">{{ item.content }}</div>
                </div>
            </div>
        </div>
        <div class="number-btn-box" style="margin-top: 40px">
            <div class="btn-chakan" @click="bannerClick(8)">申请试用</div>
            <div class="btn-more" @click="outTo('/warehouse')">查看更多</div>
        </div>
    </div>

    <!--KMS知识管理系统-->
    <div class="page-content">
        <div class="number-title-box">
            <div class="card-title">KMS知识管理系统</div>
            <div class="card-tips">{{ tips_7 }}</div>
        </div>
        <div class="platform-list">
            <div class="platform-card" v-for="(item,i) in kmsList" :key="i"
                 style="background-color: #fff;border: 1px solid #DBE0FF;height: 160px;">
                <div class="platform-card-title">{{ item.title }}</div>
                <div class="platform-card-content" v-html="item.content"></div>
            </div>
        </div>
        <div class="number-btn-box" style="margin-top: 40px">
            <div class="btn-chakan" @click="bannerClick(7)">申请试用</div>
            <div class="btn-more" @click="outTo('/kmsKnowledge')">查看更多</div>
        </div>
    </div>
    <new-cloude-footer></new-cloude-footer>

    <applyTrial ref="dialog"/>
</div>
</template>

<script>
import newHomeHeader from "@/components/assembly/newHomeHeander.vue";
import bannerList from "@/components/assembly/bannerList.vue";
import newCloudeHeader from "@/views/home/newCloudeHeader.vue";
import newCloudeFooter from "@/views/home/newCloudeFooter.vue";
import applyTrial from "@/components/assembly/applyTrial.vue";

export default {
    name: "numberMemnagerView",
    components: {bannerList, newHomeHeader,newCloudeHeader,newCloudeFooter,applyTrial},
    data() {
        return {
            schemeList: [
                {
                    title: '派工报工<br/>人员技能等级',
                    image: `${require('@/assets/img/home/top3_ico1.png')}`,
                    content: '通过生产任务中心，弹性调整派工量与生产任务到指定产线。解决员工派工报工受限。提升人员工作效率。'
                },
                {
                    title: '机台稼动参数<br/>点检保养<br/>',
                    image: `${require('@/assets/img/home/top3_ico2.png')}`,
                    content: '整合机台信息，实时掌握机台稼动参数，设备运行状态、保养履历，减少意外停机与故障，提升设备生产效率。'
                },
                {
                    title: '物料流转<br/>库存周转',
                    image: `${require('@/assets/img/home/top3_ico3.png')}`,
                    content: '电子看板整合订单进度工单生产状况、缺料资讯、机台状态等信息最大程度地减少停机时间，高效配置生产资源。'
                },
                {
                    title: '弹性生产<br/>流程追溯',
                    image: `${require('@/assets/img/home/top3_ico4.png')}`,
                    content: '透过产品批号回溯原材料、工序、机台、人员等信息，定位异常原因，同时满足弹性生产需求，随时应对客户验厂。'
                },
                {
                    title: '品质检测<br/>流程追溯',
                    image: `${require('@/assets/img/home/top3_ico5.png')}`,
                    content: '透过产品批号回溯原材料、工序、机台、人员等信息，定位异常原因，同时满足弹性生产需求，随时应对客户验厂。'
                },
            ],
            tips_7: 'KMS系统适用于管理知识资源的软件系统，包括知识库管理、知识分类和标签、搜索引擎、协作与分享等功能模块，帮助企业提高知识管理和利用效率。',
            tips_6: '通过数据采集设备，如条码扫描器、RFID读写器等，实现对仓库中的物品信息的准确采集和识别。通过智能化的任务分派和接收功能，指导仓库操作人员完成入库、出库、盘点、移库等各种库内作业。',
            tips_5: '整合和优化供应链的各个环节，如采购、生产、配送和销售，提高供应链的效率和可靠性。实现数据共享和协同工作，减少不必要的沟通和误解，提高工作效率和协作能力。',
            tips_4: '帮助企业获得客户，通过多种渠道收集和管理客户信息，实现对客户需求的及时响应和满足。帮助企业管理客户，通过建立客户档案、跟踪客户行为、分析客户价值等方式，实现对客户的全面了解和分类',
            tips_3: '融合最新的财务管理模式、数智技术与企业最佳实践，构建费用管理、财务会计、财务共享、财务中台、税务管理、管理会计、企业绩效、资金管理等新一代财务云应用，助力企业财务数字化转型',
            tips_2: '实现对整个供应链的有效管理，包括采购、库存、销售、财务等各个环节，提高供应链的效率和降低成本。实现对企业资源的集成和管理，包括客户、项目、合同、人力资源等各类资源，提高资源的利用率和价值。',
            tips_1: '促进企业协同办公，提高工作效率和管理水平。规范化管理企业的各种工作流程，实现在线审批、签字、盖章等操作，节省时间和成本。支持移动办公，使员工和管理者能够跨越时空障碍，随时随地进行协作和沟通。记录和跟踪企业的各类业务数据，实现对人员、资产、客户等的全面管理和控制。',
            content: '从建立企业门户网站、数字办公、财务管理、数字店铺、协同办公等功能帮助企业从传统的工作方式进化到在线办公，建立企业数字化基座。降低数字化的门槛，为每个企业构建数字化统一入口，让数字化与产业发展深度融合。',
            tips: '监控和管理制造过程，实现生产现场的透明化，提供实时、准确的生产数据，支持生产计划的执行和调度。优化和整合生产资源，包括设备、工具、人员、物料等，提高资源的利用率和效率，降低生产成本。',
            title: '借助基于物联网的解决方案让企业全面升级。实现集成化车间管理，提升现场生产效率',
            platformList: [
                {
                    title: '组织人事',
                    content: "灵活支撑直线制、职能制、矩阵制等多种组织结构，拖拽式批量调整，智能联动汇报关系，权限，薪酬信息等<br/><br/>员工全景档案：归集简历，面试，测评，薪资，假勤，绩效等全生命周期信息，支持企业人才画像建模和全景数据分析<br/><br/>无缝衔接招聘：联动offer-入职-转正-调动等流程，智能事件提醒与流程管控"
                },
                {
                    title: '薪酬管理',
                    content: "支持落地多套薪资体系，灵活自定义算薪规则；自动完成薪酬数据差异分析，准确高效完成薪酬核算<br/><br/>无缝连接报税系统，多法人实体一键算薪算税，轻松完成在线申报与缴款<br/><br/>自动集成发薪等数据，进行多维度的人工成本分摊，实现人力成本精细化管理"
                },
                {
                    title: '考勤管理',
                    content: "支持综合工时、弹性打卡、坐班、排班等多种考勤制度，并根据业务线或部门设置多种考勤及加班补偿规则<br/><br/>支持弹性班次、一天多班次、超长班次等特殊班次管理，划线排班、循环排班、按组排班等排班方式<br/><br/>自动汇总日周月报，联动核算薪资，更能智能穿透问题数据，快速完成考勤核对，提升HR管理效率"
                },
                {
                    title: '绩效管理',
                    content: "包含目标管理，沟通反馈，绩效评估，绩效分析等绩效全流程管理<br/><br/>支持 KPI、MBO、360、PBC、OKR、双轨制等多种绩效管理体系<br/><br/>帮助HR掌控绩效推进进程，分析关键截点，发现问题并有效改进<br/><br/>支持对比分析、绩效排名、历年趋势分析、绩效过程数据分析等，结合AI与机器学习技术，提升企业管理决策效率与决策能力"
                },
            ],
            erpList: [
                {title: '降低商家履约成本', content: '快递仓库成本最优 仓储拣货路径最短'},
                {title: '提升作业协同效率', content: '订单智能策略 波次/爆款打单'},
                {title: '提高企业管理效率', content: '员工绩效 科学决策 数据报表'},
            ],
            financeList: [
                {title: '企业省钱', content: '管控超支规避浪费，促进落地企业数字转型'},
                {title: '财务省时', content: '统一开发票，一张报表看清各项费用支出'},
                {title: '员工省事', content: '无需垫资无需贴票，一个网站搞定所有因公消费 '},
            ],
            crmList: [
                {title: 'ROI提升', subheading: '80%', content: '快速高效率在不同渠道获取线索、监控并提升你的广告ROI。'},
                {
                    title: '效率提升',
                    subheading: '30%',
                    content: '借助CRM为销售提供的各种工具，提高销售工作效率，加速客户成交过程。'
                },
                {
                    title: '满意度提升',
                    subheading: '50%',
                    content: '高效专业的在线服务和现场服务体系，帮助企业提升客户满意度，产生更多复购订单。'
                },
                {
                    title: '业绩增长',
                    subheading: '50%',
                    content: '业务全流程数字化，全方位提升营销、销售、服务、财务、管理等各方面的效率，智能分析帮你不断洞察业务增长点，最终获得可持续的健康增长。'
                }
            ],
            wmsIndex: 0,
            kmsList: [
                {
                    title: '完整的密钥控制权',
                    content: "提供了高安全的密钥管理服务专属版，您可以将密钥存储到自己独享的云密码机集群中，拥有云密码机的完整控制权，也就拥有了更高的密钥安全保障，从而也获得更高云上数据安全性保证。"
                },
                {
                    title: '严格设计安全合规',
                    content: "密钥管理服务经过严格的安全设计和审核，保证您的密钥在帮企数智大脑得到最严格的保护。通过使用具备中国国家密码管理局托管密码机，您可以满足相应的监管与合规要求。"
                },
                {
                    title: '广泛的云产品集成',
                    content: "密钥管理服务和云服务器、云数据库、对象存储、文件存储等产品广泛集成，您可以使用密钥管理服务中的密钥加密这些产品中的数据，帮助您保护敏感的数据资产，增强云产品默认安全能力。"
                },
                {
                    title: '可用、可靠、弹性',
                    content: "密钥管理服务在每个地域构建了多可用区冗余的密码计算能力，保证向密钥管理服务发起的请求可以得到低延迟处理。您可以根据需要，在不同地域的密钥管理服务创建足够的密钥，而不必担心底层设施的扩容或缩容。"
                },
            ],
            wmsList: [
                {
                    title: '采购管理',
                    image: [
                        `${require('@/assets/img/home/top2_d1.png')}`,
                        `${require('@/assets/img/home/top2_t1.png')}`
                    ],
                },
                {
                    title: '生产管理',
                    image: [
                        `${require('@/assets/img/home/top2_d2.png')}`,
                        `${require('@/assets/img/home/top2_t2.png')}`
                    ],
                },
                {
                    title: '拣配出库 ',
                    image: [
                        `${require('@/assets/img/home/top2_d3.png')}`,
                        `${require('@/assets/img/home/top2_t3.png')}`
                    ],
                },
                {
                    title: '仓内管理',
                    image: [
                        `${require('@/assets/img/home/top2_d4.png')}`,
                        `${require('@/assets/img/home/top2_t4.png')}`
                    ],
                }
            ],
            SCMList: [
                {title: '好用适用', content: '采购、财务、合规多部门协作，不同品类支持不同流程、显示不同页面，满足多样需求'},
                {title: '提效降本', content: '流程自动化，摆脱重复事务，采购工具让每一个价格都有据可依'},
                {title: '全程可视', content: '采购全程记录，运营情况一目了然，智能应用赋能战略决策'}
            ],
            logistics: [
                {
                    title: '物流成本降低',
                    num: '20%',
                    content: '从领料、加工到出货，全流程移动化数据采，去除不必要的人力，信息及时准确，降低错误、浪费、管理成本'
                },
                {
                    title: '作业效率提升',
                    num: '45%',
                    content: '消息及时推送，任务智能分配，整体流程优化，部门高协效同，收货入库、库内管理、拣配出货移动化管理，效率大大提升'
                },
                {
                    title: '仓库利用率提升',
                    num: '30%',
                    content: '仓库空间合理规划，各类容器精准细分，人员、设备充分利用，有效减少资源浪费，提高仓库昨用率'
                },
                {
                    title: '库存准确率提升至',
                    num: '100%',
                    content: '库存高效盘点，仓库高度透明，信息实时查看，准确率大大提升。库存安全预警，大大降低库存风险，有效保证生产'
                },
                {
                    title: '生产质量提升',
                    num: '25%',
                    content: '物流各环节层层关联，可以快速追溯到车间、机台、作业人员等，生产品质持续得以改善'
                },
                {
                    title: '订单交付准时率提升',
                    num: '80%',
                    content: '仓库周转效率提高，快速完成出入库，同时降 低出错率。单次出货时间大大缩短，订单准时交付率大幅提升'
                },
            ],
            //服务平台（1：数智工厂 2：数字办公 3：财务管理 4：ERP企业资源系统 5：CRM客户关系管理系统 6:SCM供应链管理系统 7：KMS知识管理系统 8：WMS仓库管理系统 9：数字应用 10：企业大数据 11：知识产权大数据智能监测平台解决方案 ）
            // servicePlatform: 1,
        }
    },
    computed: {
        WMSImgSrc() {
            return this.wmsList[this.wmsIndex].image
        }
    },
    methods: {
        outTo(url) {
            this.$router.push(url);
        },
        bannerClick(num) {
            // this.servicePlatform = num;
            this.$refs.dialog.showApply(num)
        }
    }
}
</script>

<style lang="scss" scoped>
.erp-img {
  background-image: url('~@/assets/img/home/18361_gYOL5KJac7.png');
  background-size: cover;
}
.page-content {
  padding: 60px 0;
}
.BGI2 {
  background-image: url('~@/assets/img/home/2007_QJMTAbc1_Y.png');
  background-size: cover;
}
.logistics-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  &-cell {
    width: 390px;
    height: 190px;
    box-sizing: border-box;
    background-color: #272A37;
    padding: 30px 30px 0;
    margin-bottom: 12px;
    color: #fff;
    &-title {
      display: flex;
      align-items: center;
      font-size: 18px;
      &-left {
        font-weight: bold;
      }
      &-right {
        color: #FE273B;
        font-weight: bold;
        margin-left: 20px;
      }
    }
    .logistics-box-content {
      font-size: 14px;
      margin-top: 20px;
      line-height: 1.8;
    }
  }
}
.wmsTabs {
  width: 600px;
  height: 50px;
  background-color: #fff;
  border-radius: 25px;
  display: flex;
  align-items: center;
  margin: auto;
  border: 1px solid #DBE0FF;
  box-sizing: border-box;
  overflow: hidden;
  &-item {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
  }
  .isActive {
    background-color: #1935DE;
    color: #fff;
  }
}
.scmBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-card {
    width: 390px;
    height: 140px;
    box-sizing: border-box;
    padding: 30px 35px 0;
    text-align: center;
    color: #333;
    &-title {
      font-size: 24px;
      margin-bottom: 16px;
    }
    &-tips {
      font-size: 14px;
      line-height: 1.6;
    }
    &:nth-child(1) {
      background-color: #FFF2E9;
    }
    &:nth-child(2) {
      background-color: #EBF3FF;
    }
    &:nth-child(3) {
      background-color: #E5FFFE;
    }
  }
}
.crmBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &-card {
    width: 290px;
    height: 320px;
    box-sizing: border-box;
    padding: 30px 20px;
    color: #333;
    &-title {
      font-size: 24px;
      margin-bottom: 10px;
    }
    &-sub {
      font-size: 30px;
      font-weight: bold;
    }
    .crmBox-line {
      width: 100%;
      height: 0;
      border: 1px dashed #fff;
      margin: 30px 0;
    }
    .crmBox-content {
      font-size: 16px;
      line-height: 1.6;
    }
    &:nth-child(1) {
      background-color: #D3D3C7;
    }
    &:nth-child(2) {
      background-color: #AFC0DB;
    }
    &:nth-child(3) {
      background-color: #C8C5D0;
    }
    &:nth-child(4) {
      background-color: #B1D2E2;
    }
  }
}
.card-title {
  font-size: 30px;
  font-weight: bold;
  color: #333;
  padding-bottom: 30px;
}
.card-tips {
  font-size: 16px;
  line-height: 1.4;
}
.number-title-box {
  width: 1200px;
  margin: auto;

}
.finance {
  display: flex;
  justify-content: space-between;
  &-left {
    .finance-cell {
      margin-bottom: 60px;
      &-title {
        font-size: 22px;
        font-weight: bold;
        color: #333;
        margin-bottom: 14px;
      }
      &-tips {
        color: #666666;
        font-size: 16px;
      }
    }
    .left-btns {
      display: flex;
    }
  }
}
.number-btn-box {
  width: 1200px;
  margin: auto;
  display: flex;
  margin-top: 20px;
}
.erp-container {
  width: 1200px;
  margin: auto;
  padding-top: 40px;
  .left {
    width: 800px;
  }
}
.platform-list {
  display: flex;
  align-items: center;
  width: 1200px;
  margin: auto;
  justify-content: space-between;
  margin-top: 40px;
  flex-wrap: wrap;
  .platform-card {
    width: 590px;
    height: 260px;
    padding: 34px 30px 0;
    box-sizing: border-box;
    margin-bottom: 20px;
    &-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 18px;
    }
    &-content {
      font-size: 16px;
      color: #666;
    }
    &:nth-child(1) {
      background-color: #E2F5FF;
    }
    &:nth-child(2) {
      background-color: #FFEFF4;
    }
    &:nth-child(3) {
      background-color: #FFF8EE;
    }
    &:nth-child(4) {
      background-color: #E2FFF8;
    }
  }
}
.left-btns-box{
    display: flex;
    width: 1200px;
    margin:60px auto 0;
}
.btn-chakan {
  width: 160px;
  height: 50px;
  background: #1935DE;
  font-size: 18px;
  font-family: 'SourceHanSansCN';
  color: #FFFFFF;
  line-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #1935DE;
  cursor: pointer;
}
.btn-more {
  width: 160px;
  height: 50px;
  border: 1px solid #1935DE;
  font-size: 18px;
  font-family: 'SourceHanSansCN';
  color: #1935DE;
  line-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
}
</style>